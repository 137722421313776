import { useState } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
// assets
// import { DocIllustration } from '../../../assets';
// componenets
import ContactPopover from './ContactPopover';

// ----------------------------------------------------------------------

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
    // '&::hover': {
    //   // '&::after': {
    //   //   animation: 'ripple 1.2s infinite ease-in-out',
    //   // },
    //   // animation: 'ripple 1.2s infinite ease-in-out',
    //   // color: 'red',
    // },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const LargeAvatar = styled(Avatar)(({ theme }) => ({
  width: 72,
  height: 72,
  border: `2px solid ${theme.palette.background.paper}`,
}));

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const theme = useTheme();
  // State
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <Stack
      spacing={2}
      sx={{
        borderTop: `1px solid ${theme.palette.grey[500_16]}`,
        width: 1,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
      }}
    >
      <ButtonBase
        sx={{ width: '100%', px: 4, py: 2 }}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setPopoverAnchorEl(event.currentTarget);
        }}
      >
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="flex-start"
          sx={{ width: '100%' }}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <LargeAvatar alt="AI Assistant Avatar" src="/images/avatars/aiavatar2.jpg" />
          </StyledBadge>
          <div>
            <Typography align="left" variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Sophia
            </Typography>
            <Typography align="left" variant="body2" sx={{ color: 'text.secondary' }}>
              Your AI Assistant
            </Typography>
            <Typography
              align="left"
              variant="caption"
              component="div"
              sx={{ color: 'text.secondary' }}
            >
              1.3.1
            </Typography>
          </div>
        </Stack>
      </ButtonBase>
      <ContactPopover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        closePopup={() => setPopoverAnchorEl(null)}
      />
    </Stack>
  );
}
