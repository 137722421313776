// Lib
import { EnqueueSnackbar } from 'notistack';
// Models
import EventModel, { EventPromptModel } from '../@types/models/EventModel';
import EventTypeModel, {
  EventTypesProposalModel,
  EventTypeProposalType,
} from '../@types/models/EventTypeModel';
// API
import { getModels, getModel, postModel, deleteModel, deleteModels, TimeOrderType } from './base';

export const getEvents = async (
  dasboardId: string,
  eventsType: string,
  orderType: TimeOrderType,
  selectedEmailIntegrationId: string | null,
  page: number | null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<Array<EventModel>> => {
  let url = `/dashboard/${dasboardId}/events/?type=${eventsType.toLowerCase()}&orderby=${orderType}&page=${page}`;
  if (selectedEmailIntegrationId) {
    url += `&filterbyintegration=${selectedEmailIntegrationId}`;
  }
  const models = (await getModels(url, errorEnqueueSnackbar)) as Array<EventModel>;
  return models || [];
};

export const updateEventTypeModel = async (
  dasboardId: string,
  eventTypeId: string,
  attribute: string,
  value: boolean,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<EventTypeModel> => {
  let url = `/dashboard/${dasboardId}/type/${eventTypeId}/edit`;
  const models = (await postModel(
    url,
    { [attribute]: value },
    errorEnqueueSnackbar,
  )) as EventTypeModel;
  return models || [];
};

export const getEvent = async (
  eventId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<EventModel> => {
  const model = (await getModel(`/event/${eventId}`, errorEnqueueSnackbar)) as EventModel;
  // if (model.attachements) {
  //   model.attachements = JSON.parse(model.attachements as any);
  // }
  return model;
};

export const getEventPrompt = async (
  eventId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<EventPromptModel> => {
  const model = (await getModel(
    `/event/${eventId}/ai/prompt`,
    errorEnqueueSnackbar,
  )) as EventPromptModel;
  return model;
};

export const getEventsTypes = async (
  dashboardId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<Array<EventTypeModel>> => {
  const models = await getModels(`/dashboard/${dashboardId}/events/types`, errorEnqueueSnackbar);
  return models ? (models as Array<EventTypeModel>) : [];
};

export const getEventsTypesFromIntegration = async (
  integrationId: string,
  moduleApiName: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<EventTypesProposalModel | null> => {
  const model = await getModel(
    `/integrations/${integrationId}/${moduleApiName}/events/types`,
    errorEnqueueSnackbar,
  );
  return model as EventTypesProposalModel | null;
};

export const postEventsTypesImportFromIntegration = async (
  integrationId: string,
  moduleApiName: string,
  labelAttributeName: string,
  descriptionAttributeName: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null> => {
  const model = await postModel(
    `/integrations/${integrationId}/${moduleApiName}/events/types/crm/import`,
    { label: labelAttributeName, description: descriptionAttributeName },
    errorEnqueueSnackbar,
  );
  return null;
};

export const postApprovedEvent = async (
  eventId: string,
  caseId: string | null | undefined = null,
  eventTypeId: string | null | undefined = null,
  deadline: string | null | undefined = null,
  courtDate: string | null | undefined = null,
  disputeAmount: number | null = null,
  additional: object | null = null,
  changeReason: null | string = null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<EventModel> => {
  const model = (await postModel(
    `/event/${eventId}/approve`,
    { changeReason, caseId, eventTypeId, deadline, courtDate, disputeAmount, additional },
    errorEnqueueSnackbar,
  )) as EventModel;
  return model;
};

export const demoDeleteEvents = async (
  dashboardId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<Array<EventTypeModel>> => {
  const models = await getModels(
    `/dashboard/${dashboardId}/events/delete_for_demo`,
    errorEnqueueSnackbar,
  );
  return models ? (models as Array<EventTypeModel>) : [];
};

export const postEventsTypes = async (
  dashboardId: string,
  newTypes: Array<EventTypeProposalType>,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null> => {
  await postModel(`/dashboard/${dashboardId}/types/edit`, { new: newTypes }, errorEnqueueSnackbar);
  return null;
};

export const deleteAllEventTypes = async (
  dashboardId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null> => {
  await deleteModels(`/dashboard/${dashboardId}/types`, errorEnqueueSnackbar);
  return null;
};

export const deleteEventType = async (
  dashboardId: string,
  eventTypeId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null> => {
  await deleteModel(`/dashboard/${dashboardId}/type/${eventTypeId}`, errorEnqueueSnackbar);
  return null;
};
