import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  square?: boolean;
}

export default function Logo({ disabledLink = false, square = false, sx }: Props) {
  const logo = (
    // <Box sx={{ width: 220, ...sx }}>
    //   <Typography>Neura-Law</Typography>
    // </Box>
    <Box sx={sx}>
      <img
        style={{ maxHeight: 48 }}
        alt="Logo"
        src={'/logo/' + (square ? 'neuralaw-logo.png' : 'neuralaw-logo-text.png')}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
