// Lib
import { EnqueueSnackbar } from 'notistack';
// Models
import DashboardMemberModel from '../@types/models/DashboardMemberModel';
import DashboardModel from '../@types/models/DashboardModel';
import InviteModel from '../@types/models/InviteModel';
import PreviousStatsModel from '../@types/models/PreviousStatsModel';
import StatsModel from '../@types/models/StatsModel';
import PromtModel from '../@types/models/PromtModel';
// API
import { getModel, getModels, postModel } from './base';

export const apiGetDashboardStats = async (
  dashboardId: string,
  selectedEmailIntegrationId: null | string = null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | StatsModel> => {
  let url = `/dashboard/${dashboardId}/stats/`;
  if (selectedEmailIntegrationId) {
    url += `?filterbyintegration=${selectedEmailIntegrationId}`;
  }
  const model = (await getModel(url, errorEnqueueSnackbar)) as null | StatsModel;
  return model;
};

export const apiGetDashboardPreviousStats = async (
  dashboardId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | PreviousStatsModel> => {
  const model = (await getModel(
    `/dashboard/${dashboardId}/stats/previous`,
    errorEnqueueSnackbar,
  )) as null | PreviousStatsModel;
  return model;
};

export const apiGetDashboards = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<Array<DashboardModel>> => {
  const models = await getModels('/user/dashboards', errorEnqueueSnackbar);
  return models ? (models as Array<DashboardModel>) : [];
};

export const apiGetDashboardMembers = async (
  dashboardId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<Array<DashboardMemberModel>> => {
  const models = await getModels(`/dashboard/${dashboardId}/members`, errorEnqueueSnackbar);
  return models ? (models as Array<DashboardMemberModel>) : [];
};

export const apiInviteToDashboard = async (
  dashboardId: string,
  email: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<InviteModel | null> => {
  const models = await postModel(
    `/dashboard/${dashboardId}/invite`,
    { email },
    errorEnqueueSnackbar,
  );
  return models ? (models as InviteModel) : null;
};

export const apiGetDashboardPrompt = async (
  dashboardId: string,
  errorEnqueueSnackbar: EnqueueSnackbar | null = null,
): Promise<PromtModel | null> => {
  const models = await getModel(`/dashboard/${dashboardId}/promt`, errorEnqueueSnackbar);
  return models ? (models as PromtModel) : null;
};

export const apiPostDashboardPrompt = async (
  dashboardId: string,
  promt: string,
  errorEnqueueSnackbar: EnqueueSnackbar | null = null,
): Promise<PromtModel | null> => {
  const models = await postModel(
    `/dashboard/${dashboardId}/promt`,
    { promt },
    errorEnqueueSnackbar,
  );
  return models ? (models as PromtModel) : null;
};
