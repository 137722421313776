import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export const SUPPORTED_TIMEZONES: Record<string, string> = {
  '0:00': 'Universal timezone',
  '4:00': 'UAE (Abu Dhabi)',
  '5:30': 'India (New Delhi)',
  '8:00': 'Philippines (Manila)',
};

export const getDefaultTimezoneOffset = (): string => {
  let timezoneOffset = String(-1 * (new Date().getTimezoneOffset() / 60)).replace('.', ':');
  if (timezoneOffset.indexOf(':') === -1) {
    timezoneOffset = timezoneOffset + ':00';
  }
  // Always pad.
  timezoneOffset = (timezoneOffset + '00').slice(0, 4);
  if (!SUPPORTED_TIMEZONES[timezoneOffset]) {
    timezoneOffset = '0:00';
  }
  console.log(timezoneOffset);
  return timezoneOffset;
};

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number | null | undefined) {
  return date ? format(new Date(date), 'dd MMM yyyy p') : '';
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export const fLocaleString = (date: Date | string | number) =>
  date ? new Date(date).toLocaleString('sv', { timeZoneName: 'short' }) : null;
