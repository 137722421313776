import { useState, useEffect } from 'react';
// Libs
import { SnackbarProvider, useSnackbar } from 'notistack';
import { GoogleOAuthProvider, CredentialResponse } from '@react-oauth/google';
// @mui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// theme
import ThemeProvider from './theme';
// components
import { ProgressBarStyle } from './components/ProgressBar';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import LoadingScreen from './components/LoadingScreen';
// Context
import UserContext from './contexts/UserContext';
// Models
import StatsModel from './@types/models/StatsModel';
import UserModel from './@types/models/UserModel';
import DashboardModel from './@types/models/DashboardModel';
import IntegrationModel from './@types/models/IntegrationModel';
import EventTypeModel from './@types/models/EventTypeModel';
// API
import { googleAuthenticate } from './api/base';
import { apiGetCurrentUser } from './api/user';
import { apiGetDashboards, apiGetDashboardStats } from './api/dashboard';
import { getIntegrations } from './api/integration';
import { getEventsTypes } from './api/event';
// Utils
import { getAccessTokenCookieData } from './utils/cookie';
// routes
import Router from './routes';
import Dashboard from './pages/DashboardPage';

// ----------------------------------------------------------------------

const SELECTED_DASHBOARD_ID = 'SELECTED_DASHBOARD_ID';

// ----------------------------------------------------------------------

const googleClientId = '982214844130-pklm4r2o529mmi32j0c982pp4dsgbs08.apps.googleusercontent.com';

// ----------------------------------------------------------------------

export default function App() {
  // Definitions
  const { enqueueSnackbar } = useSnackbar();
  // State
  const [userModel, setUserModel] = useState<UserModel | null | undefined>(undefined);
  const [dashboardModels, setDashboardModels] = useState<Array<DashboardModel> | null>(null);
  const [selectedDashboardId, setSelectedDashboardId] = useState<string | null>(null);
  const [statsModel, setStatsModel] = useState<null | StatsModel>(null);

  const [eventTypeModels, setEventTypeModels] = useState<null | Array<EventTypeModel>>(null);
  const [integrationModels, setIntegrationModels] = useState<Array<IntegrationModel> | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const getUser = async () => {
    setLoading(true);
    const [user, dashboards] = await Promise.all([
      apiGetCurrentUser(enqueueSnackbar),
      apiGetDashboards(enqueueSnackbar),
    ]);

    let dashboardId = null;
    // Try to fetch dashboard id from the local storage first.
    const selectedDashboardIdFromStorage = localStorage.getItem(SELECTED_DASHBOARD_ID);
    if (dashboards?.length) {
      if (selectedDashboardIdFromStorage) {
        const previousDashboardModel = dashboards.find(
          (dashboardModel: DashboardModel) => dashboardModel.id === selectedDashboardIdFromStorage,
        );
        if (previousDashboardModel) {
          dashboardId = previousDashboardModel.id;
        }
      } else {
        dashboardId = dashboards[0].id;
      }
    }

    setDashboardModels(dashboards);
    setUserModel(user);
    setLoading(false);

    if (dashboardId) {
      setSelectedDashboardId(dashboardId);
    }
  };

  const googleSignInSuccess = (credentialResponse: CredentialResponse): void => {
    googleAuthenticate(credentialResponse).then(getUser);
  };

  const updateDashboardId = (dashboardId: string) => {
    if (dashboardId && dashboardId !== selectedDashboardId) {
      setSelectedDashboardId(dashboardId);
      setStatsModel(null);
      // Save the item in the local storage.
      localStorage.setItem(SELECTED_DASHBOARD_ID, dashboardId);
    }
  };

  useEffect(() => {
    if (selectedDashboardId) {
      setStatsModel(null);
      getIntegrations(selectedDashboardId).then(setIntegrationModels);
      getEventsTypes(selectedDashboardId).then((eventTypeModels: Array<EventTypeModel>) => {
        setEventTypeModels(eventTypeModels);
      });
      apiGetDashboardStats(selectedDashboardId).then(setStatsModel);
    } else {
      setEventTypeModels(null);
    }
  }, [selectedDashboardId]);

  useEffect(() => {
    const authToken = getAccessTokenCookieData();
    if (authToken) {
      getUser();
    } else {
      setUserModel(null);
    }
  }, []);

  return (
    <ThemeProvider key={selectedDashboardId}>
      <ThemeColorPresets>
        <RtlLayout>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <ScrollToTop />
            <SnackbarProvider autoHideDuration={5500} maxSnack={3}>
              {userModel !== undefined ? (
                <GoogleOAuthProvider key={userModel?.id} clientId={googleClientId}>
                  <UserContext.Provider
                    value={{
                      userModel,
                      dashboardModels,
                      eventTypeModels,
                      selectedDashboardId,
                      updateDashboardId,
                      integrationModels,
                      statsModel,
                      googleSignInSuccess,
                    }}
                  >
                    <Router userModel={userModel} />
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </UserContext.Provider>
                </GoogleOAuthProvider>
              ) : (
                <LoadingScreen />
              )}
            </SnackbarProvider>
          </MotionLazyContainer>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
