import { Link } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// Icons
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import AddIcon from '@mui/icons-material/Add';
// Models
import DashboardModel from '../@types/models/DashboardModel';
// Util
import { truncate } from '../utils/formatText';

type Props = {
  dashboardModels: null | undefined | Array<DashboardModel>;
  selectedDashboardId: null | string;
  updateDashboardId: null | ((dashboardId: string) => void);
};

export default function DashboardButton({
  dashboardModels,
  selectedDashboardId,
  updateDashboardId,
}: Props) {
  const theme = useTheme();

  return (
    <TextField
      autoComplete="off"
      // fullWidth={true}
      select={true}
      // label="Category"
      size="small"
      margin="none"
      value={selectedDashboardId}
      sx={{ minWidth: 220 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SpaceDashboardIcon />
          </InputAdornment>
        ),
      }}
      // onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCategoryId(event.target.value)}
    >
      {dashboardModels?.map((dashboardModel: DashboardModel, idx: number) => (
        <MenuItem
          key={`${idx}#${dashboardModel.id}`}
          value={dashboardModel.id}
          onClick={() => updateDashboardId && updateDashboardId(dashboardModel.id)}
          sx={{
            borderRight: `4px solid ${
              dashboardModel.role === 0 ? 'transparent' : theme.palette.primary.main
            }`,
          }}
        >
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {truncate(dashboardModel.name, 64)}
          </div>
        </MenuItem>
      ))}
      <MenuItem component={Link} to="/dashboard" disableRipple={true}>
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="button">Add New Dashboard</Typography>
      </MenuItem>
    </TextField>
  );
}
