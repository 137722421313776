// Lib
import { EnqueueSnackbar } from 'notistack';
// Models
import EventModel from '../@types/models/EventModel';
import ThreadModel from '../@types/models/ThreadModel';
import IntegrationModel from '../@types/models/IntegrationModel';
import EventTypeModel from '../@types/models/EventTypeModel';
// API Models
import { getModel, getModels, postModel } from './base';

export const getIntegration = async (
  integrationId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<IntegrationModel | null> => {
  const model = await getModel(`/integration/${integrationId}`, errorEnqueueSnackbar);
  return model ? (model as IntegrationModel) : null;
};

export const postUpdateIntegration = async (
  integrationId: string,
  settings: Record<string, any>,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<IntegrationModel | null> => {
  const model = await postModel(
    `/integration/${integrationId}/update`,
    settings,
    errorEnqueueSnackbar,
  );
  return model ? (model as IntegrationModel) : null;
};

export const getUpdatedIntegrationEventsTypes = async (
  integrationId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<Array<EventTypeModel>> => {
  const models = await getModels(
    `/integrations/${integrationId}/events/types/crm/update`,
    errorEnqueueSnackbar,
  );
  return models ? (models as Array<EventTypeModel>) : [];
};

export const getIntegrationThreads = async (
  integrationId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<Array<ThreadModel>> => {
  const models = await getModels(`/integrations/${integrationId}/threads`, errorEnqueueSnackbar);
  return models ? (models as Array<ThreadModel>) : [];
};

export const getIntegrationThreadEvent = async (
  integrationId: string,
  threadId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | EventModel> => {
  const model = await getModel(
    `/integrations/${integrationId}/thread/${threadId}/event`,
    errorEnqueueSnackbar,
  );
  return model ? (model as EventModel) : null;
};

export const getIntegrations = async (
  dashboardId: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<Array<IntegrationModel>> => {
  const models = await getModels(`/dashboard/${dashboardId}/integrations`, errorEnqueueSnackbar);
  return models ? (models as Array<IntegrationModel>) : [];
};
