import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// Layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// Components
import LoadingScreen from '../components/LoadingScreen';
import Settings from '../components/settings';
// Models
import UserModel from '../@types/models/UserModel';

// ----------------------------------------------------------------------

type Props = {
  userModel?: UserModel | null | undefined;
};

function LoggedRouter(): React.ReactElement {
  return (
    <>
      <Settings />
      {useRoutes([
        {
          path: '/',
          element: <Navigate to="/dashboard" replace />,
        },
        {
          path: '/',
          element: <DashboardLayout />,
          children: [
            { path: 'dashboard', element: <DashboardPage /> },
            { path: 'events/new', element: <EventsPage eventsType="NEW" /> },
            { path: 'events/new/create', element: <EventManuallyCreatePage /> },
            { path: 'events/processing', element: <EventsPage eventsType="PROCESSING" /> },
            { path: 'events/history', element: <EventsPage eventsType="APPROVED" /> },
            { path: 'events/:eventId', element: <EventPage /> },
            { path: 'types', element: <EventTypesPage /> },
            { path: 'workflows', element: <WorkflowsPage /> },
            { path: 'integrations', element: <IntegrationsPage /> },
            { path: 'integrations/:integrationId', element: <IntegrationPage /> },
            {
              path: 'integrations/:integrationId/thread/:threadId',
              element: <IntegrationThreadPage />,
            },
            { path: 'team', element: <TeamPage /> },
          ],
        },
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: '404', element: <NotFoundPage /> },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
      ])}
    </>
  );
}

// ----------------------------------------------------------------------

function NotLoggedRouter(): React.ReactElement {
  return (
    <>
      {useRoutes([
        {
          path: '/',
          element: <LoginPage />,
        },
        { path: '*', element: <Navigate to="/" replace /> },
      ])}
    </>
  );
}

// ----------------------------------------------------------------------

export default function Router({ userModel }: Props): React.ReactElement {
  return Boolean(userModel) ? <LoggedRouter /> : <NotLoggedRouter />;
}

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// Login
const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
// Pages
const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));
const EventsPage = Loadable(lazy(() => import('../pages/EventsPage')));
const EventPage = Loadable(lazy(() => import('../pages/EventPage')));
const EventManuallyCreatePage = Loadable(lazy(() => import('../pages/EventManuallyCreatePage')));
const WorkflowsPage = Loadable(lazy(() => import('../pages/WorkflowsPage')));
const EventTypesPage = Loadable(lazy(() => import('../pages/EventTypesPage')));
const IntegrationsPage = Loadable(lazy(() => import('../pages/IntegrationsPage')));
const IntegrationPage = Loadable(lazy(() => import('../pages/IntegrationPage')));
const IntegrationThreadPage = Loadable(lazy(() => import('../pages/IntegrationThreadPage')));
const TeamPage = Loadable(lazy(() => import('../pages/TeamPage')));
// Errors
const NotFoundPage = Loadable(lazy(() => import('../pages/Error404Page')));
