export function removeAmpLt(str: null | undefined | string): string {
  if (!str) return '';
  // return str.replace(/&*(amp;|lt;|gt;)?/g, '');
  return unescapeSpecialCharacters(str);
}

export function removeTitleAmpLt(str: null | undefined | string): string {
  if (!str) return '';
  return str.replace(/&*(amp;|lt;|gt;)?/g, '');
  // return unescapeSpecialCharacters(str);
}

export function unescapeSpecialCharacters(inputString: string) {
  // Use regex to replace special characters with their escaped counterparts
  return inputString
    .replaceAll('\\\\', '\\')
    .replaceAll("\\'", "'")
    .replaceAll('&amp;', '&')
    .replaceAll('\\"', '"')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>');
}

export function capitalizeTheFirstLetter(str: null | undefined | string): string {
  return str && str.length ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
}

export function truncate(str: null | string, maxlength: number): string {
  if (!str) return '';
  return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
}

export function attributeToLabel(str: string): string {
  return str && str.length
    ? str
        .split('_')
        .map((s) => capitalizeTheFirstLetter(s))
        .join(' ')
    : str;
}
