// @mui icons.
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PeopleIcon from '@mui/icons-material/People';
import PowerIcon from '@mui/icons-material/Power';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
// Model
import UserModel from '../../../@types/models/UserModel';
// type
import { NavSectionConfigProps } from '../../../components/nav-section/type';
// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

const ICONS = {
  dashboard: <DashboardIcon />,
  events: <ForwardToInboxIcon />,
  workflow: <AccountTreeIcon />,
  categories: <TypeSpecimenIcon />,
  integration: <PowerIcon />,
  team: <PeopleIcon />,
};

export const getNavConfigForUser = (
  userModel: undefined | null | UserModel,
): Array<NavSectionConfigProps> => [
  {
    subheader: 'My space',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      {
        title: 'Events',
        path: '/events',
        // info: <span>223</span>,
        icon: ICONS.events,
        children: [
          { title: 'New', path: '/events/new' },
          { title: 'Processing', path: '/events/processing' },
          { title: 'History', path: '/events/history' },
        ],
      },
      { title: 'Workflows', path: '/workflows', icon: ICONS.workflow },
      { title: 'Event Types', path: '/types', icon: ICONS.categories },
      { title: 'Integrations', path: '/integrations', icon: ICONS.integration },
      // { title: 'IntegrationX', path: '/integration/id', icon: ICONS.integration },
      { title: 'Team', path: '/team', icon: ICONS.team },
    ],
  },
];
