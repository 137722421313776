/**
 * User Context
 *
 * The React Context for Employer Portal.
 * @link https://reactjs.org/docs/context.html
 */
import { createContext } from 'react';
// Lib
import { CredentialResponse } from '@react-oauth/google';
// Models
import DashboardModel from '../@types/models/DashboardModel';
import EventTypeModel from '../@types/models/EventTypeModel';
import IntegrationModel from '../@types/models/IntegrationModel';
import StatsModel from '../@types/models/StatsModel';
import UserModel from '../@types/models/UserModel';

export interface UserContextType {
  userModel: null | UserModel;
  dashboardModels: null | Array<DashboardModel>;
  eventTypeModels: null | Array<EventTypeModel>;
  selectedDashboardId: null | string;
  updateDashboardId: null | ((dashboardId: string) => void);
  integrationModels: null | Array<IntegrationModel>;
  statsModel: null | StatsModel;
  googleSignInSuccess?: null | ((credentialResponse: CredentialResponse) => void);
}

const UserContext = createContext<UserContextType>({
  userModel: null,
  eventTypeModels: null,
  dashboardModels: null,
  selectedDashboardId: null,
  integrationModels: null,
  statsModel: null,
  googleSignInSuccess: null,
  updateDashboardId: null,
});
export default UserContext;
